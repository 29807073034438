import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import remark from "remark"
import recommended from "remark-preset-lint-recommended"
import remarkHtml from "remark-html"
import Image from "../components/image"
import Footer from "../components/footer/footer.component"
import Header from "../components/header/header.component"
import "../styles/styles.css"
import SEO from "../components/seo"

import privacy from '../../static/images/privacy-policy.svg'

const PrivacyPolicy = () => {
  const data = useStaticQuery(graphql`
    query Privacyquery {
      markdownRemark(fileAbsolutePath: { regex: "/privacy.md/" }) {
        frontmatter {
          about
          privacyimage
          title
        }
      }
    }
  `)

  const toHTML = value =>
    remark().use(recommended).use(remarkHtml).processSync(value).toString()

  function createMarkup(data) {
    const dataHtml = toHTML(data)

    return { __html: dataHtml }
  }

  return (
    <div>
      <SEO title="Privacy Policy" url="https://tokorame.co.id/privacy-policy" />
      <Header />
      <div className="container-policy">
        <div className="policy">
          <h1>{data.markdownRemark.frontmatter.title}</h1>
          {/* <Image
            name={data.markdownRemark.frontmatter.privacyimage.replace(
              "../src/images/",
              ""
            )}
          /> */}
          <img src={privacy} className="rounded mx-auto d-block"></img>
        </div>
        <div
          className="policy-content"
          dangerouslySetInnerHTML={createMarkup(
            data.markdownRemark.frontmatter.about
          )}
        ></div>
      </div>
      <Footer />
    </div>
  )
}

export default PrivacyPolicy
